import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { getInitials, getClassNames } from "@utils/helpers";
import { authContext } from "@context/auth";

// Assets
import { ReactComponent as CartIcon } from "@icons/cart.svg";
import { ReactComponent as PersonOutline } from "@icons/person_outline.svg";
import { ReactComponent as ReturnsIcon } from "@icons/return.svg";
import { ReactComponent as RetailCoLogo } from "@icons/retail_co_logo.svg";
import { ReactComponent as SwitchProfileIcon } from "@icons/switch-profile.svg";
import { useSelector } from "react-redux";
import { isRegisteredSelector } from "../../redux/slice/registeredSlice.js";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isRegistered = useSelector(isRegisteredSelector);
  const { setShopperData } = useContext(authContext);
  const shopperData = JSON.parse(localStorage.getItem("shopperData"));
  const { fullname, project_identifier } = shopperData || { fullname: "" };

  useEffect(() => {
    if (shopperData) {
      setShopperData(shopperData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [activeNav, setActiveNav] = useState(1);

  useEffect(() => {
    if (location.pathname.includes("shop")) {
      setActiveNav(2);
    } else if (location.pathname.includes("returns")) {
      setActiveNav(4);
    } else {
      setActiveNav(1);
    }
  }, [location]);

  const switchProfile = () => {
    localStorage.clear();
    navigate("/");
  };

  const navhandler = (nav) => {
    navigate(nav);
  };

  return (
    <>
      <div className="flex flex-col gap-2 bg-white pt-4 px-2">
        <RetailCoLogo />
        <div className="flex gap-x-3 py-12 px-3 ">
          <div className="w-[92px] h-[92px] min-w-[92px] min-h-[92px] bg-trud-black3 rounded-full text-white flex text-4xl items-center justify-center">
            {getInitials(fullname)}
          </div>
          <div className="flex flex-col justify-center">
            <span className="text-body-black mt-2">Hi,</span>
            <span className="text-blacked text-2xl whitespace-nowrap">
              {fullname.split(" ")[0]}
            </span>
            {/* <span className='bg-stroke mt-1 p-2 rounded text-sm leading-4 text-grey-content-secondary'>
            {project_identifier}
          </span> */}
          </div>
        </div>
        <div className="bg-white flex-1 flex flex-col gap-2 font-inter font-medium text-sm text-content-primary">
          <div
            className={getClassNames(
              "hover:bg-stroke-primary  flex  items-center py-2 px-4 gap-x-3 cursor-pointer",
              activeNav === 1 ? "bg-stroke-primary" : ""
            )}
            onClick={() => navhandler("/dashboard/1")}
          >
            <PersonOutline />
            <span className="">Profile</span>
          </div>
          {isRegistered && (
            <>
              <div
                className={getClassNames(
                  "hover:bg-stroke-primary  flex  items-center py-2 px-4 gap-x-3 cursor-pointer",
                  activeNav === 2 ? "bg-stroke-primary" : ""
                )}
                onClick={() => {
                  navhandler("/dashboard/1/shop");
                }}
              >
                <CartIcon className="" />
                <span className="">Shop</span>
              </div>

              <div
                className={getClassNames(
                  "hover:bg-stroke-primary  flex  items-center py-2 px-4 gap-x-3 cursor-pointer",
                  activeNav === 4 ? "bg-stroke-primary" : ""
                )}
                onClick={() => navhandler("/dashboard/1/returns")}
              >
                <ReturnsIcon />
                <span className="">Returns</span>
              </div>
            </>
          )}
          <div
            className="flex hover:bg-stroke-primary  items-center py-2 px-4 gap-x-3 cursor-pointer"
            onClick={() => switchProfile()}
          >
            <SwitchProfileIcon />
            <span className="">Switch consumer profile</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
