import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { DemoGuidePopoverContext } from "@context/demoGuidePopover";
import DemoGuidePopover from "@components/DemoGuidePopover";

// Helpers
import { getClassNames, getScoreColorWithRisk } from "@utils/helpers";

// Components
import { Button } from "@components/button";

export const ShopperContactCard = ({ shopperData }) => {
  return (
    <div className="w-full flex flex-col min-w-[347px] pt-6 rounded-lg bg-grey-810 border border-grey-stroke-primary overflow-hidden">
      <div className="flex-1 px-8 pb-10 rounded-lg bg-grey-810">
        <h4 className="text-[2rem]">{shopperData?.fullname}</h4>
        <p className="max-w-[215px] mt-4 text-sm1 leading-[17px] text-grey-content-secondary">
          {shopperData?.Address
            ? shopperData.Address[0]?.StreetName
            : `50 Avenue des Pr'es, Montigny-le-bretonneux 78180, Île-de-France
          France`}
        </p>
      </div>
      <div className="flex items-center justify-between px-8 py-6 bg-white">
        <span className="text-[2rem] text-grey-content-tertiary">
          {shopperData?.project_identifier}
        </span>
        <div className="text-sm leading-4 text-body-black py-1 px-2 rounded bg-white border border-stroke-primary">
          Apollo Bank
        </div>
      </div>
    </div>
  );
};

export const ShopperTrustIndexInfoCard = ({ shopperData, score }) => {
  const navigate = useNavigate();
  const { shopperTrustIndexPopUp, setShopperTrustIndexPopUp } = useContext(
    DemoGuidePopoverContext
  );

  return (
    <div className="w-full min-w-[347px] pt-6 rounded-lg bg-grey-810 border border-grey-stroke-primary overflow-hidden">
      <div className="px-8 pb-10 rounded-lg bg-grey-810">
        <h4 className="text-[22px]">Consumer Trust Index</h4>
        <p className="max-w-[354px] mt-4 text-base1 leading-[17px] text-grey-content-tertiary">
          Enjoy personalised experiences tailored to you like instant refunds
          and payment upon delivery.
        </p>
        <div className="flex gap-5">
          <Button
            title="View"
            className="mt-10"
            onClick={() => navigate("/shopper-trust-index")}
          />
          <Button title="Connect another account" className="mt-10" disabled />
        </div>
      </div>
      <div
        className="flex items-center justify-between px-8 py-3 bg-white"
        id="shopper-score"
      >
        <span className="text-[2rem] text-grey-content-tertiary">
          {shopperData?.fullname}
        </span>
        <div
          className={getClassNames(
            "flex items-center justify-center text-black text-[42px] bg-stroke rounded-full w-[74px] h-[74px]",
            getScoreColorWithRisk(shopperData?.risk_level)
          )}
        >
          {shopperData?.total_score}
        </div>
      </div>
      <DemoGuidePopover
        title="View Consumer Trust Index"
        body="Take a look at the consumer’s complete fraud profile, assessed through the lens of the console's configurations."
        el={() => document.getElementById("shopper-score")}
        topOffset={110}
        leftOffset={0}
        maxWidth="max-w-[380px]"
        arrowPosition="top"
        open={shopperTrustIndexPopUp}
        setOpen={setShopperTrustIndexPopUp}
      />
    </div>
  );
};
