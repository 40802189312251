import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

// Helpers
import { getClassNames } from "@utils/helpers";

// Components
import { Button } from "@components/button";

// Assets
import { ReactComponent as BigTick } from "@icons/bigtick.svg";
import { authContext } from "@context/auth";

const RefundSuccess = () => {
  const { shopperData } = useContext(authContext);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  setTimeout(() => {
    setShow(true);
  }, 200);

  return (
    <div className="h-screen overflow-hidden">
      <div className="h-full flex flex-col max-w-[270px] lg:max-w-[493px] mx-auto items-center justify-center">
        <BigTick
          className={getClassNames(
            "transition-all duration-500",
            show ? "opacity-100 translate-y-0" : "-translate-y-20 opacity-0"
          )}
        />
        <div
          className={getClassNames(
            "transition-all duration-500 mt-16 flex flex-col items-center ",
            show ? "opacity-100 translate-y-0" : "translate-y-20 opacity-0"
          )}
        >
          <h4 className="header4 text-body-black text-center">
            {shopperData?.risk_level === "LOW_RISK_SHOPPER"
              ? "Refund processed successfully!"
              : "Your refund is being processed"}
          </h4>
          <p className="paragraph2 mt-4 text-desc-grey text-lg leading-xl2 text-center">
            {shopperData?.risk_level === "LOW_RISK_SHOPPER"
              ? "You should receive your refund within 24 hours."
              : "You parcel has been received in order and we are processing your refund."}
          </p>
          <div className="flex items-center gap-x-2 py-10">
            <Button
              title="Done"
              category="1"
              color="dark"
              className="w-fit"
              onClick={() => navigate("/end-of-demo")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefundSuccess;
