import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

// Utils
import { getClassNames } from "@utils/helpers";
import { axiosInstance } from "@utils/baseAxios";

// Components
import { Button } from "@components/button";
import Footer from "@components/Footer.js";
import Input from "@components/input";

// Assets
import { ReactComponent as ApolloBankIcon } from "@icons/apollo_bank.svg";
import { useNavigate } from "react-router-dom";
import { DemoGuidePopoverContext } from "@context/demoGuidePopover";
import DemoGuidePopover from "@components/DemoGuidePopover";

const LoginBank = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { loginPopUp, setLoginPopUp } = useContext(DemoGuidePopoverContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const onSubmit = async ({ username, password }) => {
    setLoading(true);
    await axiosInstance
      .post("/create_open_banking_session", {
        username,
        password,
      })
      .then(async (response) => {
        localStorage.setItem(
          "tokens",
          JSON.stringify(response?.data?.mastercard_tokens)
        );
        localStorage.setItem("user", JSON.stringify(response?.data?.data));
        navigate("/authorization");
      })
      .catch((err) => {
        toast.error(
          "Please check username and password. Credentials are case sensitive."
        );
      });
    setLoading(false);
  };
  return (
    <div className="bg-white">
      <div className="h-screen bg-[#0F30471A] overflow-scroll">
        <div className={getClassNames(" max-w-[1440px] mx-auto")}>
          <div className="container-auth  min-h-[52px] py-4 px-10 flex items-center justify-between">
            <ApolloBankIcon className="" />
          </div>
        </div>
        <div className="flex flex-col h-screen max-w-[1440px] mx-auto">
          <div
            className={getClassNames(
              "h-full w-full py-16 mx-auto container-auth",
              "flex-1 flex flex-col h-full rounded items-center"
            )}
          >
            <div
              className={getClassNames(
                "w-full max-w-[832px] mx-auto flex-1 mb-10",
                "flex flex-col items-center"
              )}
            >
              <div className="w-full max-w-[433px] h-full ">
                <div className="bg-white rounded-lg p-8 w-full" id="login-form">
                  <h3 className="text-[22px] mb-8">Log in to Apollo Bank</h3>
                  <DemoGuidePopover
                    title="Log in with the consumer credentials"
                    body={`Log in using the credentials shared by the <u><a href="https://sbaas.mastercardservices.com/mastercard/home/bankbotCustomers?kickoff=true" target="_blank" style="color:#FBFBFB"> Sandbox-as-a-Service bankbot</a></u> for the consumer you've selected.`}
                    el={() => document.getElementById("login-form")}
                    topOffset={20}
                    leftOffset={450}
                    arrowPosition="left"
                    open={loginPopUp}
                    setOpen={setLoginPopUp}
                  />
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex-1 w-full h-full"
                  >
                    <div className="flex flex-col gap-y-4 w-full">
                      <Input
                        name={"username"}
                        label={"Internet Banking User ID"}
                        category="auth"
                        {...{ register, errors }}
                        rules={{
                          required: true,
                        }}
                        labelStyle="text-desc-grey"
                        placeholder="Username"
                      />
                      <Input
                        name={"password"}
                        type={"password"}
                        label={"Password"}
                        category="auth"
                        {...{ register, errors }}
                        rules={{
                          required: true,
                        }}
                        labelStyle="text-desc-grey"
                        placeholder="Password"
                      />
                    </div>

                    <Button
                      title="Continue"
                      color="dark"
                      size="custom"
                      className="mt-14 w-full"
                      loading={loading}
                      // disabled={!isValid}
                      type="submit"
                    />
                  </form>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginBank;
