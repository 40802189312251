import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

// Context
import { authContext } from "@context/auth";

// Utils
import { getClassNames } from "@utils/helpers";

// Components

// Assets
import { ReactComponent as RetailCoLogo } from "@icons/retail_co_logo.svg";
import { ReactComponent as ReturnQrImg } from "@images/return_qr_code.svg";

const ReturnQrCode = () => {
  const navigate = useNavigate();

  const { shopperData } = useContext(authContext);
  return (
    <div className="flex flex-col h-full  w-full mx-auto max-w-[695px]">
      <div className={getClassNames("pt-3")}>
        <div className="container mx-auto min-h-[52px] relative">
          <RetailCoLogo className="" />
        </div>
      </div>
      <div className="h-full overflow-auto">
        <div
          className={getClassNames(
            "h-full w-full py-16 container-auth",
            "flex-1 flex flex-col h-full rounded "
          )}
        >
          <div
            className={getClassNames(
              "w-full flex-1 mb-10",
              "flex flex-col"
            )}
          >
            <div className="flex-1 px-14 py-5 rounded-lg bg-grey-810 w-full">
              <h4 className="header4">Return QR Code is ready</h4>
              {shopperData?.risk_level === "LOW_RISK_SHOPPER" ? (
                <p className="mt-4 text-base1 text-grey-content-secondary">
                  Please drop your parcel at the closest{" "}
                  <span className="text-black">Ship Co</span> store. Your refund
                  will be processed instantly when the code is scanned by our
                  shipping partner.
                </p>
              ) : (
                <p className="mt-4 text-base1 text-grey-content-secondary">
                  Please drop your parcel at the closest{" "}
                  <span className="text-black">Ship Co</span> store. Your refund
                  will be processed once your parcel has been received in the
                  warehouse.
                </p>
              )}
            </div>

            <ReturnQrImg className="mx-auto mt-14" />

            <div className="flex justify-center w-full mt-8">
              <button
                className="w-full max-w-[404px] bg-body-black py-3 px-8 text-white cursor-pointer h-fit"
                onClick={() => navigate("success")}
              >
                {shopperData?.risk_level === "LOW_RISK_SHOPPER"
                  ? "Drop parcel at Ship Co"
                  : "Parcel received at warehouse"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnQrCode;
