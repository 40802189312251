import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

// Contexts
import { authContext } from "@context/auth";
import CookieContextProvider from "@context/cookie";

// Pages
import { Dashboard } from "@pages/dashboard";
import { ShopperConfiguration } from "@pages/stp";
import { RetailerHome } from "@pages/retailer";
import Profile from "@pages/retailer/profile";
import Returns from "@pages/retailer/returns";
import SetupDeclined from "@pages/setupDeclined";
import SetupSuccess from "@pages/setupSuccess";
import Shop from "@pages/retailer/shop";
import Wallet from "@pages/retailer/wallet";
import MockBankConsentAuthorization from "@pages/retailer/mockBankConsentAuthorization";
import PayAfterDelivery from "@pages/retailer/payAfterDelivery";
import PayByBank from "@pages/stp/components/payByBank";
import PaymentAuthorization from "@pages/retailer/paymentAuthorization";
import OrderConfirmation from "@pages/retailer/orderConfirmation";
import OrderConfirmationAfter from "@pages/retailer/orderConfirmationAfterDelivery";
import ReturnQrCode from "@pages/retailer/returnQrCode";
import RefundSuccess from "@pages/retailer/returnSuccess";
import LoginBank from "@pages/retailer/loginBank";
import LoginBankPayment from "@pages/retailer/loginBankPayment";
import DemoGuidePopoverContextProvider from "@context/demoGuidePopover";
import SelectShopper from "@pages/selectShopper";
import { ButtonContextProvider } from "@components/ControlButton";
import EndDemo from "@pages/endDemo";
import Login from "@pages/retailer/login";
import Signup from "@pages/retailer/signup";
import CreatePasskey from "@pages/retailer/create_passkey";
import Passkey from "@pages/retailer/passkey";
import LoginWithTrudenty from "@pages/auth/trudentyLogin";
import ConsentAuthorization from "@pages/retailer/consentAuthorization";

const App = () => {
  const { hasCookie } = useContext(authContext);

  return (
    <Router>
      <CookieContextProvider>
      <DemoGuidePopoverContextProvider>
        {!hasCookie ? (
          <div className="h-screen bg-white overflow-y-hidden">
            <Routes>
              <Route path="/protect/login" element={<LoginWithTrudenty />} />
            </Routes>
          </div>
        ) : (
          <ButtonContextProvider>
            <Routes>
              <Route path="/" element={<SelectShopper />} />
              <Route path="/_dashboard" element={<RetailerHome />} />
              <Route path="/dashboard" element={<Profile />} />
              <Route path="/dashboard/:id" element={<Profile />} />

              <Route path="login" element={<Login />} />
              <Route path="login" element={<Login />} />
              <Route path="signup" element={<Signup />} />
              <Route path="create-passkey" element={<CreatePasskey />} />
              <Route path="passkey" element={<Passkey />} />

              <Route path="/dashboard/:id/returns" element={<Returns />} />
              <Route path="/dashboard/:id/wallet" element={<Wallet />} />
              <Route path="/dashboard/:id/shop" element={<Shop />} />
              <Route path="setup" element={<ShopperConfiguration />} />
              <Route
                path="authorization"
                element={<MockBankConsentAuthorization />}
              />
              <Route path="pay-by-bank" element={<PayByBank />} />
              <Route path="bank-login" element={<LoginBank />} />
              <Route path="bank-login-payment" element={<LoginBankPayment />} />
              <Route
                path="consent-authorization"
                element={<ConsentAuthorization />}
              />
              <Route
                path="payment-authorization"
                element={<PaymentAuthorization />}
              />
              <Route
                path="order-confirmation"
                element={<OrderConfirmation />}
              />
              <Route
                path="order-confirmation-after"
                element={<OrderConfirmationAfter />}
              />
              <Route
                path="/dashboard/:id/returns/qr-scan"
                element={<ReturnQrCode />}
              />
              <Route
                path="/dashboard/:id/returns/qr-scan/success"
                element={<RefundSuccess />}
              />
              <Route path="setup-success" element={<SetupSuccess />} />
              <Route path="end-of-demo" element={<EndDemo />} />
              <Route path="setup-declined" element={<SetupDeclined />} />
              <Route path="pay-after-delivery" element={<PayAfterDelivery />} />
              <Route path="shopper-trust-index" element={<Dashboard />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </ButtonContextProvider>
        )}
        </DemoGuidePopoverContextProvider>
      </CookieContextProvider>
    </Router>
  );
};

export default App;
