const addDays = (days) => {
    var result = new Date();
    result.setDate(result.getDate() + days);
    return result.toDateString();
  };
  export const terms = [
    {
      title: "Keeping your data safe",
      body: "We take security seriously. We use strong measures to protect your bank info. We will never ask you to <br />share your bank login details.",
    },
    {
      title: "How long your consent lasts",
      body: "Your consent remains active for either 90 or 180 days, depending on your bank’s region. It will continue <br />until you choose to revoke it, either through your bank or the merchant’s app.",
    },
    {
      title: "Your right to say no ",
      body: "You can change your mind and withdraw your consent anytime. This won't affect anything we've done <br>before you withdrew.",
    },
    {
      title: "How we use your info ",
      body: "We'll only use your bank data for what we've told you and to provide our services. We might share it with<br /> trusted partners to help us do that.",
    },
    {
      title: "Your privacy",
      body: `Your personal info will be handled according to the law. You can ask to see, correct, or delete your data if <br />needed.<br />
      By clicking "I Agree," you're saying you understand and are okay with all of this.
      `,
    },
  ];
  