import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

// Helpers
import { getClassNames } from "@utils/helpers";

// Components
import { Button } from "@components/button";

// Assets
import { ReactComponent as BigTick } from "@icons/bigtick.svg";
import { DemoGuidePopoverContext } from "@context/demoGuidePopover";

const SetupSuccess = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  setTimeout(() => {
    setShow(true);
  }, 200);
  const { setShopperTrustIndexPopUp } = useContext(DemoGuidePopoverContext);

  return (
    <div className="h-screen overflow-hidden">
      <div className="h-full flex flex-col max-w-[270px] lg:max-w-[372px] mx-auto items-center justify-center">
        <BigTick
          className={getClassNames(
            "transition-all duration-500",
            show ? "opacity-100 translate-y-0" : "-translate-y-20 opacity-0"
          )}
        />
        <div
          className={getClassNames(
            "transition-all duration-500 mt-16 flex flex-col items-center ",
            show ? "opacity-100 translate-y-0" : "translate-y-20 opacity-0"
          )}
        >
          <h4 className="header4 text-body-black text-center">
            Thank you! Your Consumer Trust Index has been calculated.
          </h4>
          <p className="paragraph2 mt-4 text-desc-grey text-lg leading-xl2 text-center">
            Consent has been granted for the next 180 days.
          </p>
          <div className="flex items-center gap-x-2 py-10">
            <Button
              title="Continue"
              category="1"
              color="dark"
              className="w-fit"
              onClick={() => {
                navigate("/dashboard/1/wallet");
                setShopperTrustIndexPopUp(true);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupSuccess;
