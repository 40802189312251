import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Utils
import { axiosInstance } from "@utils/baseAxios";

// Components
import Header from "@components/header";
import { RiskTag, ScoreBubbleMid } from "./dashboard/components/shoppersCharts";
import { Button } from "@components/button";
import { authContext } from "@context/auth";
import ConfigChangesPopover from "@components/configChangesPopover";
import { getInitials } from "@utils/helpers";
import { useDispatch } from "react-redux";
import { setIsRegistered } from "../redux/slice/registeredSlice";

// Assets

const TrustedMessage = {
  low_risk_message:
    "Trusted consumers show no reports of first-party fraud against them and have data profiles indicating a low propensity for fraud.",
  medium_risk_message:
    "Medium Risk Consumers typically have no reports of first-party fraud, however they have data patterns consistent with those of high risk consumers with reported instances of fraud.",
  high_risk_message:
    "High Risk Consumers have actual reports for first-party fraud and have data patterns indicating a high propensity for fraud.",
};

let TrustedUserList = [
  {
    id: 0,
    fullname: "Samantha Jonhs",
    email: "samantha@gmail.com",
  },
  {
    id: 1,
    fullname: "Tom Cruise",
    email: "tom@gmail.com",
  },
  {
    id: 2,
    fullname: "John Wall",
    email: "johnwall@gmail.com",
  },
  {
    id: 3,
    fullname: "Darrell Taylor",
    email: "darrell@gmail.com",
  },
  {
    id: 4,
    fullname: "Bruce Wells",
    email: "brucel2003@gmail.com",
  },
];

const SelectShopper = () => {
  const navigate = useNavigate();
  const { setShopperData, setIsAuthenticated } = useContext(authContext);
  const [consumerList, setConsumerList] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    const getConsumerList = async () => {
      const email = localStorage.getItem("email");
      // if (!email) {
      //   await axiosInstance
      //   .delete("/clear_session")
      //   .then(() => {
      //     setIsAuthenticated(false);
      //   })
      //   .catch((err) => console.log(err));
      // };
      try {
        const res = await axiosInstance.get(
          `/trusted_shoppers/usersList?email=${email}`
        );
        if (res.status === 200) {
          setConsumerList(res.data);
        }
      } catch (error) {
        console.log("ERROR", error);
      }
    };
    getConsumerList();
  }, [setIsAuthenticated]);

  useEffect(() => {
    dispatch(setIsRegistered({ isRegistered: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if(consumerList?.length) {
  //     consumerList.map((consumer) => {
  //       const findIndex = TrustedUserList.findIndex(
  //         (users) => users.id == consumer.id
  //       );
  //       TrustedUserList[findIndex] = {
  //         ...consumer,
  //         ...TrustedUserList[findIndex],
  //       };
  //     });
  //   } else {
  //     getConsumerList();
  //   }
  // }, [consumerList]);

  const getShopperDetails = async (id, fullname, email) => {
    localStorage.setItem("email", email);
    await axiosInstance
      .get(`/trusted_shoppers/${id}?mastercard_user_name=${fullname}`)
      .then(async (response) => {
        localStorage.setItem("shopperData", JSON.stringify(response?.data));
        setShopperData(response?.data);
        navigate("/dashboard/1");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="gradient-3 relative">
      <ConfigChangesPopover />
      <div className="-z-10 absolute inset-0 bg-person-consumer blur-[17px] bg-cover"></div>
      <div className="mx-auto flex flex-col">
        <Header title="Consumer Demo" />
        <div className="container-centered mx-auto">
          <h1 className=" text-[2.5rem] mt-16 mb-6">
            Select a consumer profile
          </h1>
          <div className="flex flex-wrap justify-center gap-6">
            {consumerList?.map((user, index) => (
              <div
                key={user?.id}
                className="bg-white group rounded-2xl sm:w-1/1 md:w-1/2 lg:w-1/3 xl:w-1/3 xl:w-[400px] flex flex-col relative overflow-hidden"
              >
                <div className="border-header px-10 py-3 bg-grey-10/[77] flex items-center justify-between">
                  <div className="flex items-center gap-x-3">
                    <div className="flex rounded-full min-w-[40px] min-h-[40px] items-center justify-center bg-blacked text-white header6">
                      {getInitials(user?.fullname)}
                    </div>
                    <span className="text-grey-content-secondary header4">
                      {user?.fullname}
                    </span>
                  </div>
                  <ScoreBubbleMid
                    score={user?.total_score}
                    risk={user?.risk_level}
                  />
                </div>
                <div className="p-10 flex-1 flex flex-col items-start">
                  <RiskTag risk={user?.risk_level} />
                  <p className="mt-6 paragraph-large flex-1 text-grey-content-secondary z-10 backdrop-blur-[1px]">
                    {user?.risk_level === "LOW_RISK_SHOPPER"
                      ? TrustedMessage.low_risk_message
                      : user?.risk_level === "MEDIUM_RISK_SHOPPER"
                      ? TrustedMessage.medium_risk_message
                      : TrustedMessage.high_risk_message}
                  </p>
                </div>
                <div className="border-t border-stroke group">
                  <div className="px-6 py-10">
                    <Button
                      title="Select experience"
                      color=""
                      onClick={() =>
                        getShopperDetails(
                          user?.id,
                          user?.fullname,
                          TrustedUserList[user?.id]?.email
                        )
                      }
                      className="w-full py-3 text-xl bg-stroke group-hover:bg-black group-hover:text-white"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectShopper;
