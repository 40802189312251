import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isRegistered: false,
};

const isRegisteredSlice = createSlice({
  name: "registered",
  initialState,
  reducers: {
    setIsRegistered(state, action) {
      state.isRegistered = action?.payload?.isRegistered ? true : false;
    },
  },
});

export const isRegisteredSelector = (state) => {
  return state.registered.isRegistered;
};

export const { reducer } = isRegisteredSlice;

export const { setIsRegistered } = isRegisteredSlice.actions;

export default isRegisteredSlice;
